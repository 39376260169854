import { DefaultLayout } from '../components/layout/layout';
import BetterLink from '../components/link/better-link';

import { useUser } from '../hooks/useUser';

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

HomePage.getLayout = (page) => {
    return <DefaultLayout>{page}</DefaultLayout>;
};

export const getStaticProps = async ({ locale }) => {
    return {
        props: {
            ...(await serverSideTranslations(locale, ['common', 'home'])),
        },
    };
};

export default function HomePage() {
    const router = useRouter();
    const { user, passwordRecovery } = useUser();

    const { t } = useTranslation();

    useEffect(() => {
        if (passwordRecovery) router.push('/reset-password');
        if (user && !router.asPath.includes('no-redirect'))
            router.push('/get-started');
    }, [user, passwordRecovery, router]);

    return (
        <>
            <Head>
                <title>Võ Hoàng Phúc | {t('common:home')}</title>
                <link rel="icon" href="/favicon.ico" />
            </Head>

            <div className="flex items-center font-mono">
                <div className="container mx-auto flex flex-col items-center justify-between px-6 py-4">
                    <div className="mt-32 flex w-full flex-col items-center">
                        <p className="my-6 text-center text-xl md:text-4xl">
                            {t('home:personalGreetings')}.
                        </p>

                        <h2 className="max-w-3xl py-2 text-center text-4xl font-bold text-zinc-800 dark:text-zinc-100 md:text-5xl">
                            {t('home:personalDescription')}.
                        </h2>

                        <div className="my-8 flex flex-col items-center justify-center md:my-24">
                            <div className="mx-8 my-2 text-sm text-zinc-600 dark:text-zinc-200">
                                {t('home:connectWithMeQuestion')}?
                            </div>
                            <BetterLink
                                href="/contact"
                                className="rounded-lg border-2 border-zinc-800 bg-transparent px-4 py-3 text-sm font-bold capitalize text-zinc-800 transition duration-150 hover:bg-zinc-800 hover:text-white dark:border-zinc-100 dark:text-zinc-100 dark:hover:bg-zinc-100 dark:hover:text-black md:px-10 md:text-base"
                            >
                                {t('home:connectWithMe')}{' '}
                                <span className="font-sans font-bold">;)</span>
                            </BetterLink>
                        </div>

                        <div className="text-center text-lg font-semibold md:mx-16 md:text-3xl">
                            {t('home:iterateThrough')}{' '}
                            <span className="underline decoration-blue-400">
                                {t('home:differentPhases')}
                            </span>
                            , {t('home:turnIdeasInto')}{' '}
                            <span className="underline decoration-purple-400">
                                {t('home:worldClassProducts')}
                            </span>
                            .
                        </div>

                        <div className="mt-8 grid grid-cols-1 gap-8 md:mx-16 md:grid-cols-6 lg:grid-cols-5">
                            <div className="flex h-32 w-full items-center justify-center rounded-lg border-4 border-green-400 p-4 text-3xl font-semibold text-green-500 transition duration-300 hover:bg-green-100 dark:text-green-400 dark:hover:bg-green-500/30 md:col-span-2 lg:col-span-1">
                                {t('home:analyze')}
                            </div>
                            <div className="flex h-32 w-full items-center justify-center rounded-lg border-4 border-blue-400 p-4 text-3xl font-semibold text-blue-500 transition duration-300 hover:bg-blue-100 dark:text-blue-400 dark:hover:bg-blue-500/30 md:col-span-2 lg:col-span-1">
                                {t('home:design')}
                            </div>
                            <div className="flex h-32 w-full items-center justify-center rounded-lg border-4 border-purple-400 p-4 text-3xl font-semibold text-purple-500 transition duration-300 hover:bg-purple-100 dark:text-purple-400 dark:hover:bg-purple-500/30 md:col-span-2 lg:col-span-1">
                                {t('home:develop')}
                            </div>
                            <div className="flex h-32 w-full items-center justify-center rounded-lg border-4 border-yellow-400 p-4 text-3xl font-semibold text-yellow-500 transition duration-300 hover:bg-yellow-100 dark:text-yellow-400 dark:hover:bg-yellow-500/30 md:col-span-3 lg:col-span-1">
                                {t('home:test')}
                            </div>
                            <div className="flex h-32 w-full items-center justify-center rounded-lg border-4 border-red-400 p-4 text-3xl font-semibold text-red-500 transition duration-300 hover:bg-red-100 dark:text-red-400 dark:hover:bg-red-500/30 md:col-span-3 lg:col-span-1">
                                {t('home:deploy')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
